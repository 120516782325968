import { Icon, IconProps } from "..";

export const Queens = (props: IconProps) => {
  return (
    <Icon {...props}>
      <>
        <path d="M23.3167 10.2136V8H12.6837V10.2136H23.3167Z" />
        <path d="M23.3167 10.2136L25.438 10.2135V12.4271H23.3167L23.3167 10.2136Z" />
        <path d="M12.6836 25.7864V28H23.3166V25.7864H12.6836Z" />
        <path d="M25.4621 12.4527V14.6673H23.3165V12.4527H21.1942V14.6673H19.0729V16.8809H16.9273V19.1197H21.1941V21.3333H23.3164L23.3166 25.7864L25.462 25.7866V23.5478H27.5834V12.4525L25.4621 12.4527Z" />
        <path d="M12.6837 21.333H14.8059V19.1194H12.6837V16.8806H14.8059V14.667H16.9273V12.4524H12.6836L12.6837 10.2136L10.538 10.2136V12.4524H8.41666V23.5477H10.538V25.7866L12.6836 25.7864L12.6837 21.333Z" />
      </>
    </Icon>
  );
};
