import { Icon, IconProps } from ".";

export const Warning = (props: IconProps) => {
  return (
    <Icon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.7389 6H16.2075V7.99983H13.9429V11.9995H11.6783V15.9992H9.41377V19.9988H7.14921V23.9985H4.88464V28.0002H7.14921V30H29.7971V28.0002H32.0617V23.9985H29.7971V19.9988H27.5325V15.9992H25.268V11.9995H23.0034V7.99983H20.7389V6ZM20.1316 12.9514V14.0584H20.6865V19.0344H20.1316V21.801H19.5807V23.4583H17.3668V21.801H16.8147V19.0344H16.2598V14.0584H16.8147V12.9514H17.3668V12.4004H19.5807V12.9514H20.1316ZM17.3668 26.7992V24.5881H19.5807V26.7992H17.3668Z"
      />
    </Icon>
  );
};
