import { Icon, IconProps } from "..";

export const Manhattan = (props: IconProps) => {
  return (
    <Icon {...props}>
      <>
        <path d="M17.4345 6C17.4345 6.82627 17.4345 9.58976 17.4345 10.6583H16.1286V12.5301H19.8722V10.6583H18.226C18.226 10.6583 18.226 6.82606 18.226 6L17.4345 6Z" />
        <path d="M17.3761 13.7778V22.5128H18.6239V13.7778H21.1196V23.7607H22.9914V27.5043H24.2393V30H20.4957V27.5043H19.2479V30H16.7521V27.5043H15.5043V30H11.7607V27.5043H13.0086V23.7607H14.8804V13.7778H17.3761Z" />
      </>
    </Icon>
  );
};
