import { Icon, IconProps } from "..";

export const AK47 = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <>
      <path d="M18.625 2.5H20.875V4.75H19.75V3.625H18.625V2.5Z" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.625 4.75V3.625H17.5V4.75H16.375V5.875H15.25V4.75H14.125V3.625H13V4.75H14.125V7H13V8.125H11.875V9.25H10.75V10.375H9.625V11.5H8.5V12.625H7.375V13.75H6.25V14.875H5.125V16H4V18.25H5.125V19.375H6.25V20.5H7.375V19.375H8.5V17.125H9.625V16H10.75V17.125H11.875V18.25H13V17.125H14.125V16H13V14.875H14.125V13.75H18.625V11.5H15.25V10.375H16.375V9.25H17.5V7H18.625V5.875H19.75V4.75H18.625ZM17.5 5.875V4.75H18.625V5.875H17.5ZM17.5 5.875V7H16.375V5.875H17.5ZM14.125 13.75V12.625H13V13.75H14.125ZM6.25 18.25H5.125V17.125H6.25V18.25ZM6.25 18.25H7.375V19.375H6.25V18.25Z"
        fill="currentColor"
      />
      </>
    </Icon>
  );
};
