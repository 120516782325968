import { Icon, IconProps } from "..";

export const Leatherjacket = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.90917 4.5V5.38183H7.1455V6.26367H6.26367V7.1455H5.38183V11.5547H4.5V18.6182H6.27249V13.3272H7.1455V18.6182H8.02733V19.5H11.5635V9.791H10.6817V8.90917H9.79983V8.02733H8.918V7.1455H8.03616V6.27249H8.918V5.39066H15.082V6.27249H15.9638V7.1455H15.082V8.02733H14.2002V8.90917H13.3183V9.791H12.4365V19.5H15.9727V18.6182H16.8545V13.3272H17.7275V18.6182H19.5V11.5547H18.6182V7.1455H17.7363V6.26367H16.8545V5.38183H15.0908V4.5H8.90917ZM16.8457 11.5635H15.9727V13.3183H16.8457V11.5635ZM15.9638 8.918V9.791H15.0908V8.918H15.9638ZM10.6728 10.6817V11.5547H9.79983V10.6817H10.6728ZM9.791 9.79983V10.6728H8.918V9.79983H9.791ZM8.90917 8.918V9.791H8.03616V8.918H8.90917ZM14.209 9.79983V10.6728H15.082V9.79983H14.209ZM13.3272 10.6817V11.5547H14.2002V10.6817H13.3272ZM7.15433 11.5635H8.02733V13.3183H7.15433V11.5635Z"
          fill="currentColor"
        />
      </>
    </Icon>
  );
};
