import { Icon, IconProps } from ".";

export const Bag = (props: IconProps) => {
  return (
    <Icon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.4002 14.8237H22.8V12.7063H20.4002V14.8237ZM17.9996 14.8237H20.4002V12.7063H17.9996V14.8237ZM15.5999 14.8237H17.9996V12.7063H15.5999V14.8237ZM13.2001 14.8237H15.5999V12.7063H13.2001V14.8237ZM10.8004 14.8237H13.2001V12.7063H10.8004V14.8237ZM8.39973 14.8237H10.8004V12.7063H8.39973V14.8237ZM6 16.9411H8.39973V14.8237H6V16.9411ZM6 19.0585H8.39973V16.9411H6V19.0585ZM6 21.1767H8.39973V19.0585H6V21.1767ZM6 23.2941H8.39973V21.1767H6V23.2941ZM6 25.4115H8.39973V23.2941H6V25.4115ZM8.39973 27.5289H10.8004V25.4115H8.39973V27.5289ZM10.8004 27.5289H13.2001V25.4115H10.8004V27.5289ZM13.2001 27.5289H15.5999V25.4115H13.2001V27.5289ZM15.5999 27.5289H17.9996V25.4115H15.5999V27.5289ZM17.9996 27.5289H20.4002V25.4115H17.9996V27.5289ZM20.4002 27.5289H22.8V25.4115H20.4002V27.5289ZM22.8 27.5289H25.1997V25.4115H22.8V27.5289ZM25.1997 14.8237H27.5994V12.7063H25.1997V14.8237ZM22.8 14.8237L25.1997 14.8237V12.7063L22.8 12.7063V14.8237ZM27.5993 16.9411H30V14.8237H27.5994L27.5993 16.9411ZM27.5993 19.0585H30V16.9411H27.5993V19.0585ZM27.5993 21.1767H30V19.0585H27.5993V21.1767ZM27.5993 23.2941H30V21.1767H27.5993V23.2941ZM27.5993 25.4116H30V23.2941H27.5993V25.4116ZM25.1997 27.5289L27.5993 27.529V25.4116L25.1997 25.4115V27.5289ZM20.4002 12.7063H22.8V10.5881H20.4002V12.7063ZM17.9996 10.5881H20.4002V8.4707H17.9996V10.5881ZM15.5999 10.5881H17.9996V8.4707H15.5999V10.5881ZM13.2001 12.7063H15.5999V10.5881H13.2001V12.7063Z"
      />
    </Icon>
  );
};
