import { Icon, IconProps } from "..";

export const Shrooms = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" height="60px" width="60px" {...props}>
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.69231 6H6.46154V6.69231H7.15385V7.38462H7.84615V10.1538H8.53846V10.8462H9.23077V11.5385H9.92308V12.2308H9.23077V12.9231H8.53846V13.6154H9.23077V12.9231H9.92308V12.2308H12V12.9231H12.6923V13.6154H12V14.3077H11.3077V15H12V14.3077H12.6923V13.6154H14.7692V14.3077H15.4615V15H16.1538V14.3077H15.4615V13.6154H14.7692V12.9231H15.4615V12.2308H17.5385V12.9231H18.2308V13.6154H17.5385V14.3077H18.2308V13.6154H18.9231V12.9231H19.6154V12.2308H18.9231V12.9231H18.2308V12.2308H17.5385V11.5385H18.2308V10.8462H17.5385V11.5385H15.4615V10.8462H14.7692V10.1538H15.4615V9.46154H14.7692V10.1538H12.6923V9.46154H12V8.76923H11.3077V9.46154H12V10.1538H12.6923V10.8462H12V11.5385H9.92308V10.8462H9.23077V10.1538H8.53846V9.46154H9.23077V8.76923H10.6154V8.07692H15.4615V8.76923H16.8462V9.46154H18.2308V10.1538H18.9231V10.8462H19.6154V11.5385H20.3077V12.2308H21V14.3077H20.3077V15H19.6154V15.6923H18.9231V17.7692H18.2308V18.4615H15.4615V17.0769H9.23077V17.7692H8.53846V18.4615H5.76923V17.0769H6.46154V15H5.76923V14.3077H5.07692V12.2308H4.38462V10.8462H3.69231V9.46154H3V6.69231H3.69231V6ZM5.07692 7.38462V8.07692H4.38462V7.38462H5.07692Z"
          fill="currentColor"
        />
        <path
          d="M12 11.5385V12.2308H12.6923V12.9231H14.7692V12.2308H15.4615V11.5385H14.7692V10.8462H12.6923V11.5385H12Z"
          fill="currentColor"
        />
      </>
    </Icon>
  );
};
