import { Icon, IconProps } from ".";

export const Event = (props: IconProps) => {
  return (
    <Icon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7779 21.3334H13.5557V19.111H15.7779V21.3334ZM18.0001 21.3334H15.7779V19.111H18.0001V21.3334ZM20.2222 21.3334H18.0001V19.111H20.2222V21.3334ZM22.4444 21.3334H20.2222V19.111H22.4444V21.3334ZM24.6668 21.3334H22.4444V19.111H24.6668V21.3334ZM26.889 21.3334H24.6668V19.111H26.889V21.3334ZM29.1112 21.3334H26.889V19.111H29.1112V21.3334ZM31.3333 19.111H29.1112V16.8888H31.3333V19.111ZM31.3333 16.8888H29.1112V14.6666H31.3333V16.8888ZM29.1112 14.6666H26.889V12.4445H29.1112V14.6666ZM26.889 12.4445H24.6668V10.2223H26.889V12.4445ZM24.6668 12.4445H22.4444V10.2223H24.6668V12.4445ZM22.4444 14.6666H20.2222V12.4445H22.4444V14.6666ZM20.2222 16.8888H18.0001V14.6666H20.2222V16.8888ZM20.2222 12.4445H18.0001V10.2223H20.2222V12.4445ZM18.0001 10.2223H15.7779V8H18.0001V10.2223ZM15.7779 10.2223H13.5557V8H15.7779V10.2223ZM13.5557 10.2223H11.3333V8H13.5557V10.2223ZM11.3333 12.4445H9.11116V10.2223H11.3333V12.4445ZM9.11116 14.6667H6.88897V12.4445H9.11116V14.6667ZM9.11116 16.8889H6.88897V14.6667H9.11116V16.8889ZM9.11116 19.1111H6.88897V16.8889H9.11116V19.1111ZM11.3333 21.3334H9.11116V19.1111H11.3333V21.3334ZM13.5557 21.3334H11.3333V19.1111H13.5557V21.3334ZM24.6668 23.5556H22.4445V21.3334H24.6668V23.5556ZM20.2221 23.5556H17.9999V21.3334H20.2221V23.5556ZM15.7778 23.5556H13.5556V21.3334H15.7778V23.5556ZM13.5556 25.7778H11.3333V23.5556H13.5556V25.7778ZM17.9999 25.7778H15.7778V23.5556H17.9999V25.7778ZM22.4442 25.7778H20.2221V23.5556H22.4442V25.7778ZM26.8889 25.7778H24.6667V23.5556H26.8889V25.7778ZM11.3331 23.5556H9.11093V21.3334H11.3331V23.5556ZM9.11093 25.7778H6.88874V23.5556H9.11093V25.7778ZM11.3331 28H9.11093V25.7778H11.3331V28ZM15.7778 28H13.5556L13.5556 25.7778H15.7778V28ZM20.2221 28H17.9999V25.7778H20.2221V28ZM24.6668 28H22.4444V25.7778H24.6668V28ZM6.88881 28H4.66663V25.7778H6.88881V28ZM29.1111 23.5557H26.8889V21.3335H29.1111V23.5557Z"
      />
    </Icon>
  );
};
