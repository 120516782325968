import { Icon, IconProps } from ".";

export const Gem = (props: IconProps) => {
  return (
    <Icon {...props}>
      <>
        <path d="M7.91989 16.5596H10.7999V19.4396H7.91989V16.5596Z" />
        <path d="M10.7999 19.4396L13.6798 19.4395V22.3195H10.7998L10.7999 19.4396Z" />
        <path d="M13.6798 22.3195L16.5601 22.3203V25.2003H13.6801L13.6798 22.3195Z" />
        <path d="M16.5601 25.2003L19.44 25.2002V28.0802H16.56L16.5601 25.2003Z" />
        <path d="M19.4399 22.3203H22.3199V25.2003L19.44 25.2002L19.4399 22.3203Z" />
        <path d="M22.3198 19.4395H25.1998V22.3195L22.3199 22.3203L22.3198 19.4395Z" />
        <path d="M25.2002 16.5596H28.0802V19.4396L25.1998 19.4395L25.2002 16.5596Z" />
        <path d="M28.08 10.7999V7.91992H7.92001V10.7999H5.04001V16.5599L7.91989 16.5596L7.92001 13.6799H10.8V10.7999H13.68V13.6799H16.56V10.7999H19.44V13.6799H22.32V10.7999H25.2V13.6799H28.08L28.0802 16.5596L30.96 16.5599V10.7999H28.08Z" />
        <path d="M10.8 13.6799H13.68L13.68 16.5599L10.7999 16.5596L10.8 13.6799Z" />
        <path d="M16.56 13.6799H19.44L19.44 16.5599H16.56L16.56 13.6799Z" />
        <path d="M22.32 13.6799H25.2L25.2002 16.5596L22.32 16.5599L22.32 13.6799Z" />
      </>
    </Icon>
  );
};
