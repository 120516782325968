import { Icon, IconProps } from "..";

export const Chain = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <>
        <path d="M11.2609 3.5H13.3478V4.19565H12.6522V4.8913H9.86957V4.19565H11.2609V3.5Z" fill="currentColor" />
        <path d="M9.17391 5.58696H9.86957V4.8913H9.17391V5.58696Z" fill="#currentColor" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.17391 6.28261V5.58696H8.47826V6.28261H7.78261V6.97826H7.08696V9.76087H6.3913V10.4565H5.69565V11.1522H5V13.2391H5.69565V13.9348H8.47826V13.2391H9.17391V12.5435H9.86957V11.8478H10.5652V11.1522H11.2609V7.67391H12.6522V18.1087H13.3478V18.8043H14.0435V19.5H16.1304V18.8043H16.8261V18.1087H17.5217V12.5435H16.8261V11.8478H17.5217V7.67391H16.8261V6.97826H16.1304V6.28261H15.4348V5.58696H14.7391V4.8913H14.0435V4.19565H13.3478V4.8913H12.6522V5.58696H10.5652V6.28261H9.17391ZM9.17391 7.67391H9.86957V6.97826H10.5652V6.28261H11.2609V7.67391H10.5652V8.36957H9.17391V7.67391ZM8.47826 8.36957V7.67391H9.17391V6.28261H8.47826V6.97826H7.78261V8.36957H8.47826ZM8.47826 9.06522V10.4565H7.78261V11.1522H7.08696V11.8478H6.3913V12.5435H7.78261V11.8478H8.47826V11.1522H9.17391V10.4565H9.86957V9.06522H10.5652V11.1522H9.86957V11.8478H9.17391V12.5435H8.47826V13.2391H5.69565V11.1522H6.3913V10.4565H7.08696V9.76087H7.78261V9.06522H8.47826ZM14.0435 5.58696V6.28261H14.7391V5.58696H14.0435ZM14.0435 5.58696H13.3478V4.8913H14.0435V5.58696ZM14.7391 7.67391V6.97826H16.1304V7.67391H16.8261V11.8478H16.1304V8.36957H15.4348V7.67391H14.7391ZM14.0435 9.06522H15.4348V8.36957H14.7391V7.67391H14.0435V6.97826H13.3478V6.28261H11.9565V6.97826H12.6522V7.67391H13.3478V8.36957H14.0435V9.06522ZM14.7391 12.5435V11.8478H16.1304V12.5435H16.8261V18.1087H16.1304V18.8043H14.0435V18.1087H13.3478V13.9348H14.0435V17.413H14.7391V18.1087H15.4348V17.413H16.1304V13.2391H15.4348V12.5435H14.7391ZM14.7391 12.5435H14.0435V9.06522H13.3478V13.2391H14.0435V13.9348H15.4348V13.2391H14.7391V12.5435ZM15.4348 17.413H14.7391V14.6304H15.4348V17.413ZM14.7391 9.76087H15.4348V11.1522H14.7391V9.76087Z"
          fill="currentColor"
        />
      </>
    </Icon>
  );
};
