import { Icon, IconProps } from "../archive";

export const Dojo = (props: IconProps) => {
  return (
    <Icon {...props}>
      <g transform="translate(-1, 0)">
        <path d="M18.9058 8.79337C19.192 8.79337 19.471 8.67018 19.6775 8.45279L21.2934 7.19192C21.4058 7.10496 21.4746 6.96365 21.4746 6.81873V5.95279L3.47461 5.89844V6.66293C3.47461 6.80061 3.53258 6.92742 3.63041 7.018L5.24273 8.46365C5.45287 8.69916 5.74273 8.83322 6.04345 8.83322H7.32244V11.7318L6.65577 11.0506C6.44925 10.8368 6.15939 10.7137 5.85867 10.7137L3.80432 10.7028V13.3187L7.31881 13.3586V18.4818L7.93475 17.8695C7.93475 17.8695 9.73548 16.076 9.92751 15.8839L10.0326 15.7789V10.8731C10.0181 10.6955 10.0036 10.518 9.98186 10.3405C9.96012 10.1484 9.92388 9.95279 9.89852 9.76076C9.85504 9.46728 9.78983 9.18105 9.72461 8.89481C9.72461 8.88032 9.71736 8.87308 9.72461 8.85858C9.75722 8.81148 9.97099 8.76076 10.0253 8.78612C10.3478 8.94192 10.6884 9.0325 11.0507 9.04699C11.1558 9.05061 11.2608 9.05423 11.3659 9.05423L14.105 9.04699C14.4637 9.02887 14.8007 8.94192 15.1232 8.78612C15.1775 8.76076 15.3913 8.81148 15.4239 8.85858C15.4311 8.86945 15.4239 8.88032 15.4239 8.89481C15.3587 9.18105 15.2935 9.4709 15.25 9.76076C15.221 9.95279 15.1884 10.1448 15.1666 10.3405C15.1413 10.5434 15.1268 10.7499 15.1087 10.9528V15.739L17.7282 18.4781V13.355L21.4275 13.3151V10.6992L18.9927 10.71C18.6956 10.71 18.4058 10.8332 18.1956 11.047L17.7282 11.5252V8.79699H18.9058V8.79337Z" />
        <path d="M12.4746 13.7941C13.3949 13.7941 14.141 13.0481 14.141 12.1279C14.141 11.2074 13.3949 10.4614 12.4746 10.4614C11.5542 10.4614 10.8082 11.2074 10.8082 12.1279C10.8082 13.0481 11.5542 13.7941 12.4746 13.7941Z" />
      </g>
    </Icon>
  );
};
