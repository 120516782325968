import { Icon, IconProps } from "..";

export const Acid = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 60 60" height="60px" width="60px" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.2136 12.4065H40.024V14.3107H41.9281V20.0253H40.024V23.8357H38.1198V25.7398H41.9302V23.8357H43.8343V20.0273H45.7385V18.1232H51.4531V20.0273H53.3573V23.8377H51.4531V25.7418H49.549V21.9315H47.6448V25.7418H45.7407V27.646H43.8365V29.5501H38.1219V31.4543H41.9322V33.3584H45.7426V35.2626H47.6467V40.9772H45.7426V42.8813H43.8384V44.7855H45.7426V46.6897H41.9322V40.975H43.8364V37.1646H41.9322V35.2605H36.2176V33.3563H34.3134V37.1667H32.4093V39.0708H30.5051V42.8812H32.4093V44.7853H34.3134V50.5H30.5031V48.5959H32.4072V46.6917H28.5969V44.7876H26.6927V37.1666H28.5969V35.2625H26.6927V37.1666H22.8824V39.0708H20.9782V42.8811H22.8824V44.7853H19.072V42.8811H17.1679V37.1665H19.072V35.2623H20.9762V33.3582H24.7865V31.454H19.0719V29.5499H17.1677V25.7396H13.3574V27.6437H15.2615V29.5478H9.54688V27.6437H7.64273V23.8333H9.54688V21.9292H19.0715V23.8333H20.9756V27.6437H24.786V23.8333H22.8818V16.2124H24.786V14.3083H26.6901V12.4041H28.5943V10.5H36.2152V12.4041L36.2136 12.4065ZM30.499 31.4583H28.5948V35.2686H30.499V31.4583ZM28.5948 18.1232H24.7845V21.9336H28.5948V18.1232ZM30.499 18.1232V21.9336H34.3093V18.1232H30.499ZM27.1857 21.4324H25.2816V19.5283H27.1857V21.4324ZM32.9004 21.4324H30.9962V19.5283H32.9004V21.4324Z"
      />
    </Icon>
  );
};
