import { Icon, IconProps } from "../";

export const Backpack = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.59 4.5H12.4731V5.43691H13.41V6.37383H15.2837V7.31074H16.2206V9.18457H17.1575V10.1215H18.0944V11.9953H19.0313V18.5631H18.0944V19.5H8.71625V18.5631H6.8425V17.6262H5.90563V16.6893H4.96875V9.18457H5.90563V8.24766H6.8425V7.31074H8.71625V6.37383H9.65313V5.43691H10.59V4.5ZM12.4638 5.44628H10.5994V6.37383H12.4638V5.44628ZM18.085 14.8154H14.3469V15.743H18.085V14.8154ZM11.5362 12.9322V12.0047H17.1481V12.9322H11.5362ZM11.5269 12.9416V13.8691H10.5994V12.9416H11.5269ZM7.77938 17.6168V8.25703H6.85187V9.19394H5.91499V16.6799H6.85187V17.6168H7.77938ZM9.66249 13.8785V17.6168H10.59V13.8785H9.66249Z"
          fill="currentColor"
        />
      </>
    </Icon>
  );
};
