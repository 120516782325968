import { Icon, IconProps } from ".";

type RotateType = {
  [key: string]: string;
};

const rotate: RotateType = {
  up: "rotate(0deg)",
  down: "rotate(180deg)",
  right: "rotate(90deg)",
  left: "rotate(270deg)",
};

export interface ArrowEnclosedProps {
  direction?: string;
  variant?: "arrow" | "caret" | string;
}

export const ArrowEnclosed = ({
  direction,
  variant,
  ...props
}: ArrowEnclosedProps & IconProps) => {
  let path;
  switch (variant) {
    case "caret":
      path = (
        <path d="M-1.06561e-06 18C-1.50015e-06 27.9411 8.05887 36 18 36V36C27.9411 36 36 27.9411 36 18V18C36 8.05887 27.9411 -3.52265e-07 18 -7.86805e-07V-7.86805e-07C8.05887 -1.22135e-06 -6.31067e-07 8.05887 -1.06561e-06 18V18ZM24.2771 20.0916L20.0917 20.0916L20.0917 15.908L15.9292 15.908L15.9292 20.0916L11.7667 20.0916L11.7667 24.2541L7.58308 24.2541L7.58308 20.0916L11.7667 20.0916L11.7667 15.908L15.9292 15.908L15.9292 11.7455L20.0917 11.7455L20.0917 15.908L24.2771 15.908L24.2771 20.0916L28.4395 20.0916L28.4395 24.2541L24.277 24.2541L24.2771 20.0916Z" />
      );
      break;
    case "arrow":
    default:
      path = (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6 23.9997L8.00008 23.9997L8.00008 12.0003L6 12.0003L6 23.9997ZM8.00008 12.0003L10.0002 12.0003L10.0002 10.0002L8.00008 10.0002L8.00008 12.0003ZM10.0002 10.0002L12.0003 10.0002L12.0003 8.00008L10.0002 8.00008L10.0002 10.0002ZM12.0003 8.00008L23.9997 8.00008L23.9997 6L12.0003 6L12.0003 8.00008ZM23.9997 8.00008L23.9997 10.0002L25.9998 10.0002L25.9998 8.00008L23.9997 8.00008ZM25.9998 10.0002L25.9998 12.0003L27.9999 12.0003L27.9999 10.0002L25.9998 10.0002ZM27.9999 12.0003L27.9999 23.9997L30 23.9997L30 12.0003L27.9999 12.0003ZM27.9999 23.9997L25.9998 23.9997L25.9998 25.9998L27.9999 25.9998L27.9999 23.9997ZM25.9998 25.9998L23.9997 25.9998L23.9997 27.9999L25.9998 27.9999L25.9998 25.9998ZM23.9997 27.9999L12.0003 27.9999L12.0003 30L23.9997 30L23.9997 27.9999ZM12.0003 27.9999L12.0003 25.9998L10.0002 25.9998L10.0002 27.9999L12.0003 27.9999ZM10.0002 25.9998L10.0002 23.9997L8.00008 23.9997L8.00008 25.9998L10.0002 25.9998ZM12.0003 18.0004L15.9994 18.0004L15.9994 23.9998L19.9994 23.9998L19.9994 18.0004L23.9995 18.0004L23.9995 16.0003L21.9994 16.0003L21.9994 14.0002L19.9993 14.0002L19.9993 12.0002L15.9993 12.0002L15.9993 14.0002L14.0001 14.0002L14.0001 16.0003L12 16.0003L12.0003 18.0004Z"
        />
      );
      break;
  }
  return (
    <Icon transform={rotate[direction || "up"]} {...props}>
      {path}
    </Icon>
  );
};
