import { Icon, IconProps } from "..";

export const Shoes2 = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <>
        <path
          d="M14.3684 5H19.1053V5.94737H15.3158V6.89474H19.1053V5.94737H20.0526V6.89474H21V14.4737H20.0526V15.4211H19.1053V16.3684H14.3684V17.3158H12.4737V18.2632H4.89474V17.3158H3.94737V16.3684H3V12.5789H3.94737V11.6316H6.78947V12.5789H7.73684V13.5263H8.68421V16.3684H9.63158V13.5263H8.68421V12.5789H7.73684V11.6316H6.78947V10.6842H8.68421V9.73684H11.5263V8.78947H12.4737V9.73684H13.4211V10.6842H14.3684V11.6316H16.2632V10.6842H14.3684V9.73684H13.4211V8.78947H12.4737V6.89474H13.4211V5.94737H14.3684V5Z"
          fill="currentColor"
        />
      </>
    </Icon>
  );
};
