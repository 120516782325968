import { Icon, IconProps } from ".";

export const Home = (props: IconProps) => {
  return (
    <Icon {...props}>
      <>
        <path d="M8 25.778H16.8889V27.9939H8V25.778Z" />
        <path d="M19.1111 25.7839H28V27.9998H19.1111V25.7839Z" />
        <path d="M19.1175 12.4446V10.2224H21.3333V12.4446H19.1175Z" />
        <path d="M21.3397 14.6669V12.4446H23.5556V14.6669H21.3397Z" />
        <path d="M23.5619 16.8891V14.6669H25.7778V16.8891H23.5619Z" />
        <path d="M12.4508 14.6669V12.4446H14.6667V14.6669H12.4508Z" />
        <path d="M10.2222 16.8891V14.6669H12.4381V16.8891H10.2222Z" />
        <path d="M16.8952 10.2222V8H19.1111V10.2222H16.8952Z" />
        <path d="M14.6667 23.5558V21.3335H16.8825V23.5558H14.6667Z" />
        <path d="M16.8952 21.3335V19.1113H19.1111V21.3335H16.8952Z" />
        <path d="M19.1175 23.5558V21.3335H21.3333V23.5558H19.1175Z" />
        <path d="M14.673 12.4446V10.2224H16.8889V12.4446H14.673Z" />
        <path d="M8 16.8891H10.2236V25.778H8V16.8891Z" />
        <path d="M14.6667 23.5558H16.8903V25.778H14.6667V23.5558Z" />
        <path d="M19.1111 23.5558H21.3347V25.778H19.1111V23.5558Z" />
        <path d="M25.7762 16.8891H27.9998V25.778H25.7762V16.8891Z" />
      </>
    </Icon>
  );
};
