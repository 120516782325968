import { Icon, IconProps } from ".";

export const Play = (props: IconProps) => {
  return (
    <Icon {...props}>
      <path d="M12.4811 8.51562H8.47314V28.5156H12.4811V26.5166H16.4791V24.5176H20.4771V22.5186H24.4751V20.5196H28.4731V16.5116H24.4751V14.5126H20.4771V12.5136H16.4791V10.5146H12.4811V8.51562Z" />
    </Icon>
  );
};

export const Note = (props: IconProps) => {
  return (
    <Icon {...props}>
      <path d="M21.8735 5.94434V8.22878H19.5894V17.3666H12.7372V19.651H10.4531V24.2313H12.7372V26.5158H19.6009V24.2313H21.885V12.8091H24.169V10.5247H26.4531V5.94434H21.8735Z" />
    </Icon>
  );
};

export const Pause = (props: IconProps) => {
  return (
    <Icon {...props}>
      <>
        <path d="M16.4731 8.51562H12.4731V28.5156H16.4731V8.51562Z" />
        <path d="M24.4731 8.51562H20.4731V28.5156H24.4731V8.51562Z" />
      </>
    </Icon>
  );
};

export const Backward = (props: IconProps) => {
  return (
    <Icon {...props}>
      <path d="M28.4731 11.0156L28.4731 26.0154L25.1377 26.0156L25.1377 23.5154L21.8023 23.5154L21.8023 21.0164L18.4794 21.0164L18.4794 26.0154L15.144 26.0156L15.144 23.5154L11.8086 23.5154L11.8086 21.0164L8.47314 21.0164L8.47315 16.0161L11.8086 16.0161L11.8086 13.5158L15.144 13.5158L15.144 11.0156L18.4794 11.0156L18.4794 16.0161L21.8023 16.0161L21.8023 13.5158L25.1377 13.5158L25.1377 11.0156L28.4731 11.0156Z" />
    </Icon>
  );
};

export const Forward = (props: IconProps) => {
  return (
    <Icon {...props}>
      <path d="M8.47314 26.0156V11.0159L11.8086 11.0156V13.5158H15.144V16.0149H18.4669V11.0159L21.8023 11.0156V13.5158H25.1377V16.0149H28.4731V21.0152H25.1377V23.5154H21.8023V26.0156H18.4669V21.0152H15.144V23.5154H11.8086V26.0156H8.47314Z" />
    </Icon>
  );
};

export const Volume = ({ muted, ...props }: { muted: boolean } & IconProps) => {
  return (
    <>
      {muted ? (
        <Icon viewBox="0 0 24 24" {...props}>
          <>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.81765 5.45435V6.90876H8.36324V8.36317H4V15.6367H8.36324V17.0911H9.81765V18.5455H14.1823V5.45435H9.81765ZM8.36469 8.36463V9.81904H5.45587V14.1808H8.36469V15.6352H9.81911V17.0897H12.7265V6.91021H9.81911V8.36463H8.36469Z"
              fill="currentColor"
            />
            <path
              d="M16.9686 12.7264H18.4231V11.2735H16.9686V9.81759H18.4245V11.272H19.8775V9.81759H21.3333V11.2735H19.8789V12.7264H21.3333V14.1823H19.8775V12.7279H18.4245V14.1823H16.9686V12.7264Z"
              fill="currentColor"
            />
          </>
        </Icon>
      ) : (
        <Icon viewBox="0 0 24 24" {...props}>
          <>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.81765 5.45483V6.90922H8.36324V8.3636H4V15.637H8.36324V17.0914H9.81765V18.5457H14.1823V5.45483H9.81765ZM8.36469 8.36506V9.81944H5.45587V14.1811H8.36469V15.6355H9.81911V17.0899H12.7265V6.91067H9.81911V8.36506H8.36469Z"
              fill="currentColor"
            />
            <path
              d="M18.4231 11.9996H16.9686V14.9098H19.8789V10.5466H21.3333V9.09079H18.4231V11.9996Z"
              fill="currentColor"
            />
          </>
        </Icon>
      )}
    </>
  );
};
