import { Icon, IconProps } from "../archive";

export const Cartridge = (props: IconProps) => {
  return (
    <Icon {...props}>
      <g transform="translate(3, 3)">
        <path d="M5.45902 6.45065H12.4672V4.68029H5.46078C5.46078 4.86117 5.45902 6.4673 5.45902 6.45065Z" />
        <path d="M17.3231 2.80379L13.0408 0.996428C12.7594 0.858954 12.4523 0.782 12.1394 0.770508H5.86057C5.54749 0.782022 5.24026 0.858972 4.95857 0.996428L0.676857 2.80379C0.469711 2.90946 0.296404 3.07143 0.176748 3.27118C0.0570915 3.47094 -0.00408502 3.70042 0.000211732 3.93338V11.164C0.000211732 11.3899 0.000210304 11.6158 0.225564 11.8418L1.57827 13.1973C1.80362 13.4232 1.97279 13.4232 2.25433 13.4232H5.35191C5.35191 13.6174 5.35191 15.247 5.35191 15.2294H12.6744V13.4208H5.35776V11.6158H2.02897C1.80362 11.6158 1.80362 11.3899 1.80362 11.3899V2.80379C1.80362 2.80379 1.80362 2.57787 2.02897 2.57787H15.9716C16.197 2.57787 16.197 2.80379 16.197 2.80379V11.3899C16.197 11.3899 16.197 11.6158 15.9716 11.6158H12.6762V13.4232H15.7463C16.0278 13.4232 16.197 13.4232 16.4223 13.1973L17.7744 11.8418C17.9998 11.6158 17.9998 11.3899 17.9998 11.164V3.93338C18.004 3.70043 17.9428 3.47098 17.8232 3.27124C17.7035 3.0715 17.5303 2.90951 17.3231 2.80379Z" />
      </g>
    </Icon>
  );
};
