import { Icon, IconProps } from "..";

export const PlasticBag = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.28571 3.5V4.2619H7.52381V9.59524H6.7619V11.881H6V14.9286H6.7619V17.2143H7.52381V17.9762H8.28571V18.7381H9.80952V19.5H14.381V18.7381H15.9048V17.9762H16.6667V17.2143H17.4286V15.6905H18.1905V14.1667H18.9524V11.881H18.1905V10.3571H17.4286V8.07143H16.6667V3.5H15.1429V4.2619H14.381V6.54762H13.619V8.83333H12.8571V9.59524H12.0952V10.3571H11.3333V9.59524H10.5714V7.30952H9.80952V3.5H8.28571ZM14.381 6.54762H15.1429V5.02381H15.9048V10.3571H15.1429V9.59524H14.381V6.54762ZM8.28571 5.02381H9.04762V6.54762H8.28571V5.02381Z"
          fill="currentColor"
        />
    </Icon>
  );
};
