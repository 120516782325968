import { Icon, IconProps } from ".";

export const Fist = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" {...props} fill="currentColor">
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.5588 6.32349H15.0882V7.20584H16.8529V8.08819H17.7353V9.8529H18.6176V10.7353H17.7353V15.147H16.8529V16.0294H15.9706V16.9117H12.4412V17.7941H11.5588V16.9117H9.79412V16.0294H7.14706V15.147H5.38235V14.2647H4.5V9.8529H5.38235V8.97055H7.14706V8.08819H8.91176V7.20584H11.5588V6.32349ZM11.5588 9.8529H12.4412V16.0294H11.5588V9.8529ZM9.79412 10.7353H8.91176V15.147H9.79412V10.7353ZM15.0882 8.97055H14.2059V16.0294H15.0882V8.97055ZM7.14706 11.6176H6.26471V14.2647H7.14706V11.6176Z"
          fill="currentColor"
        />
        <path d="M16.8529 17.7941V18.6764H12.4412V17.7941H16.8529Z" />
        <path d="M17.7353 16.9117V17.7941H16.8529V16.9117H17.7353Z" />
        <path d="M18.6176 16.0294V16.9117H17.7353V16.0294H18.6176Z" />
        <path d="M18.6176 16.0294H19.5V10.7353H18.6176V16.0294Z" />
      </>
    </Icon>
  );
};
