import { Icon, IconProps } from "..";

export const CentralPark = (props: IconProps) => {
  return (
    <Icon {...props}>
      <>
        <path d="M12.1667 19.1667L12.1667 16.8333L14.5 16.8333L14.5 12.1667L16.8334 12.1667L16.8334 7.5L19.1667 7.5L19.1667 12.1667L21.5 12.1667L21.5 16.8333L23.8334 16.8333L23.8334 21.5L26.1667 21.5L26.1667 26.1667L23.8334 26.1667L23.8334 23.8333L19.1667 23.8333L19.1667 28.5L16.8334 28.5L16.8334 23.8333L12.1667 23.8333L12.1667 26.1667L9.83335 26.1667L9.83335 21.5L12.1667 21.5L12.1667 19.1667Z" />
      </>
    </Icon>
  );
};
