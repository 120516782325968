import { Icon, IconProps } from ".";

export const Clock = (props: IconProps) => {
  return (
    <Icon {...props}>
      <>
        <path d="M21.8916 24.2245H23.4469V25.7798H21.8916V24.2245Z" />
        <path d="M10.9975 22.6693H12.5528V24.2246H10.9975V22.6693Z" />
        <path d="M23.4468 22.6693H25.0021V24.2246L23.4469 24.2245L23.4468 22.6693Z" />
        <path d="M10.9976 14.8856H9.44226V22.6689L10.9975 22.6693L10.9976 14.8856Z" />
        <path d="M10.9975 13.3312H12.5528V14.8866H10.9975V13.3312Z" />
        <path d="M23.4468 13.3312H25.0021V14.8866H23.4468V13.3312Z" />
        <path d="M12.5528 24.2246L14.1084 24.2245V25.7798H12.5531L12.5528 24.2246Z" />
        <path d="M21.8916 11.776H23.4469L23.4468 13.3312L21.8916 13.3314V11.776Z" />
        <path d="M17.2266 18.0046H12.553V19.5589H18.782V13.331H17.2267L17.2266 18.0046Z" />
        <path d="M14.1084 25.7798L14.1083 32H21.8916L21.8916 25.7798H14.1084Z" />
        <path d="M12.5531 11.776H14.1084V13.3314L12.5528 13.3312L12.5531 11.776Z" />
        <path d="M21.8916 11.776L21.8915 4H14.1082L14.1084 11.776H21.8916Z" />
        <path d="M25.0021 14.8866V22.6693L26.5577 22.6696V14.8863L25.0021 14.8866Z" />
      </>
    </Icon>
  );
};
