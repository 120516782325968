import { Icon, IconProps } from ".";

export const PaperIcon = (props: IconProps) => {
  return (
    <Icon width="16px" height="16px" {...props} viewBox="0 0 22 22" fill="currentColor">
      <>
        <path d="M9.43688 6.76258H8.91668V7.28278H8.39648V8.84857H8.91668V9.88897H9.44208V9.36877H9.96228V8.84857H10.4825V7.80297H9.96228V6.24238H9.43688V6.76258Z" />
        <path d="M5.27529 8.84857V8.32317H6.84109V8.84337H7.88148V9.88897H7.36129V11.9646H7.88148V12.4848H8.40168V13.005H8.92188V13.5252H9.44208V14.0454H10.4825V14.5656H13.0783V14.0454H13.5985V13.5252H14.1187V13.005H14.6389V11.4444H15.1591V10.9294H14.6389V10.404H17.2451V10.9294H16.7249V11.4496H16.2047V12.49H15.6845V13.5304H15.1643V14.0506H14.6441V14.5708H14.1239V15.091H13.6037V15.6112H9.43688V15.091H8.39648V14.5708H7.87628V14.0506H7.35608V13.5304H6.83589V12.49H6.31569V9.88897H5.79549V8.84857H5.27529Z" />
        <path d="M12.5581 7.28278H13.0783V6.76258H13.6037V8.32317H14.1239V8.84857H13.6037V9.88897H13.0835V10.4092H12.5581V9.88897H12.0379V7.80297H12.5581V7.28278Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.43688 0V0.520198H7.35608V1.0404H6.31569V1.56059H5.79549V2.08079H4.75509V2.60099H4.23489V3.12119H3.7147V3.64139H3.1945V4.16159H2.6743V5.20198H2.1541V6.24238H1.6339V6.76258H1.1137V8.84337H0.593506V13.5304H1.1137V15.6112H1.6339V16.6515H2.1541V17.1717H2.6743V17.6919H3.1945V18.2121H3.7147V18.7323H4.23489V19.2525H4.75509V19.7727H5.27529V20.2929H7.35608V20.8131H9.43688V21.3333H11.5229V20.8131H13.6037V20.2929H14.6441V19.7727H15.6845V19.2525H16.2047V18.7323H17.2451V18.2121H17.7653V17.6919H18.2854V17.1717H18.8056V16.6515H19.3258L19.3258 15.6112H19.846V14.5708H20.3662V13.0102H20.8864V11.9698H21.4066V6.76258H20.8864V5.20198H20.3662V4.16159H19.846V3.64139H19.3258V3.12119H18.8057V2.60099H18.2855V2.08079H17.7653V1.56059H17.2451V1.0404H16.7249V0.520198H14.6441V0H9.43688ZM5.80069 16.1261H5.28049V15.6059H4.76029V14.5656H4.2401V13.5252H3.7199V8.84857H4.2401V7.28798H4.76029V6.24758H5.28049V5.72738H5.80069V5.20718H6.32089V4.68699H6.84109V4.16679H7.36129V3.64659H8.40168V3.12639H9.96228V2.60619H13.5985V3.12639H15.1591V3.64659H15.6793V4.16679H16.1995V4.68699H16.7197V5.20718H17.2399V5.72738H17.7601V6.76778H18.2803V8.32837H18.8004V10.9242H18.2803V12.4848H17.7601V14.0454H17.2399V15.0858H16.7197V15.6059H16.1995V16.1261H15.6793V16.6463H14.6389V17.1665H13.5985V17.6867H12.0379V18.2069H8.92188V17.6867H7.36129V17.1665H6.32089V16.6463H5.80069V16.1261Z"
        />
      </>
    </Icon>
  );
};

export const PaperCashIcon = (props: IconProps) => {
  return (
    <Icon {...props} viewBox="0 0 24 24" fill="currentColor">
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.8944 4.19922H13.1056V5.39842H14.3048V6.59762H15.492V5.39842H16.7032V6.59762H17.9024V7.79682H19.1016V8.99602H20.3008V10.1952H21.5V11.4064H20.3008V12.6056H19.1016V13.8048H17.9024V15.004H16.7032V16.2032H15.504V17.4024H14.3048V18.6016H13.1056V19.8008H11.8944V18.6016H10.6952V17.4024H9.50799V18.6016H8.2968V17.4024H7.0976V16.2032H5.8984V15.004H4.6992V13.8048H3.5V12.5936H4.6992V11.3944H5.8984V10.1952H7.0976V8.99602H8.2968V7.79682H9.49601V6.59762H10.6952V5.39842H11.8944V4.19922ZM9.49601 16.2032V17.3904H8.30879V16.2032H9.49601ZM7.10959 16.1912H8.2968V15.004H7.10959V16.1912ZM7.0976 14.992H5.91039V13.7928H4.71119V12.6056H5.91039V11.4064H7.10959V10.2072H8.30879V9.00801H9.50799V7.80881H10.7072V6.60961H11.9064V5.41041H13.0936V6.60961H14.2928V7.79682H13.0936V8.99602H11.8944V10.1952H10.6952V11.3944H9.49601V12.5936H8.2968V13.7928H7.0976V14.992ZM13.1056 11.4064H15.492V13.7928H13.1056V11.4064Z"
        />
      </>
    </Icon>
  );
};
