import { Icon, IconProps } from ".";

export const Trophy = (props: IconProps) => {
  return (
    <Icon {...props}>
      <>
        <path d="M12.564 11.661H10.2003V16.3883H12.564V11.661Z" />
        <path d="M12.564 16.3883H14.9276V18.7519H12.564L12.564 16.3883Z" />
        <path d="M14.9277 18.752H17.2912V21.1155H14.9277V18.752Z" />
        <path d="M26.7459 11.661H24.3824V16.3883H26.7459V11.661Z" />
        <path d="M22.0186 16.3883H24.3822V18.7519H22.0186V16.3883Z" />
        <path d="M19.655 18.752H22.0185V21.1155H19.655V18.752Z" />
        <path d="M17.2913 23.4793H14.9276V25.843H12.564V28.2066H24.3823V25.843H22.0185V23.4793H19.655V21.1157H17.2914L17.2913 23.4793Z" />
        <path d="M26.7459 16.3883H29.1095V18.7519H26.7459L26.7459 16.3883Z" />
        <path d="M29.1096 11.661V16.3883H31.4731V11.661H29.1096Z" />
        <path d="M26.7459 9.29736H29.1095V11.6609H26.7459V9.29736Z" />
        <path d="M7.8367 9.29736H10.2003V11.6609H7.8367V9.29736Z" />
        <path d="M7.8367 11.661H5.47314V16.3883H7.8367V11.661Z" />
        <path d="M7.8367 16.3883H10.2003V18.7519H7.8367L7.8367 16.3883Z" />
        <path d="M24.3823 11.661V9.29746H12.564L12.564 11.661H24.3823Z" />
      </>
    </Icon>
  );
};
