import { Icon, IconProps } from "..";

export const Brooklyn = (props: IconProps) => {
  return (
    <Icon {...props}>
      <>
        <path d="M16.1532 25.8002H11.6408V28H22.9227V25.8002H18.4104V19.1997H16.1542L16.1532 25.8002Z" />
        <path d="M20.6669 16.9996H22.9231V14.7998H20.6669V16.9996Z" />
        <path d="M11.6413 14.8007V17.0005H13.8974V14.8007H11.6413Z" />
        <path d="M18.4109 19.201H20.667L20.6669 16.9996L18.4109 17.0001V19.201Z" />
        <path d="M13.8974 17.0005L13.8973 19.201H16.1535V17.0001L13.8974 17.0005Z" />
        <path d="M20.6668 8.19952V10.3993H9.38495V14.799H11.6411V12.5992H18.4106V14.799L20.6669 14.7998L20.6668 12.5992H22.9229L22.9231 14.7998L25.1791 14.799V10.3993H22.9229V8.19952H20.6668Z" />
        <path d="M25.1792 6H22.923L22.9229 8.19952L25.1792 8.1998V6Z" />{" "}
      </>
    </Icon>
  );
};
