import { Icon, IconProps } from ".";

export const CopsIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <>
        <path d="M16 2.5H17V3.5H16V2.5Z" fill="currentColor" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 4.5V3.5H13V2.5H11V3.5H8V2.5H7V3.5H6V4.5H5V5.5H4V6.5H3V7.5H4V8.5H5V10.5H4V12.5H3V16.5H4V17.5H5V18.5H6V19.5H7V20.5H9V21.5H11V22.5H13V21.5H15V20.5H17V19.5H18V18.5H19V17.5H20V16.5H21V12.5H20V10.5H19V8.5H20V7.5H21V6.5H20V5.5H19V4.5H18V3.5H17V4.5H16ZM13 4.5V5.5H16V4.5H13ZM11 4.5V3.5H13V4.5H11ZM8 4.5H11V5.5H8V4.5ZM7 4.5H6V5.5H5V6.5H4V7.5H5V8.5H6V10.5H5V12.5H4V16.5H5V17.5H6V18.5H7V19.5H9V20.5H11V21.5H13V20.5H15V19.5H17V18.5H18V17.5H19V16.5H20V12.5H19V10.5H18V8.5H19V7.5H20V6.5H19V5.5H18V4.5H17V5.5H18V6.5H19V7.5H18V8.5H17V10.5H18V12.5H19V16.5H18V17.5H17V18.5H15V19.5H13V20.5H11V19.5H9V18.5H7V17.5H6V16.5H5V12.5H6V10.5H7V8.5H6V7.5H5V6.5H6V5.5H7V4.5ZM7 4.5V3.5H8V4.5H7ZM15 13.5H14V14.5H15V16.5H13V15.5H11V16.5H9V14.5H10V13.5H9V12.5H8V11.5H10V10.5H11V9.5H13V10.5H14V11.5H16V12.5H15V13.5Z"
          fill="currentColor"
        />
      </>
    </Icon>
  );
};
