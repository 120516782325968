import { Icon, IconProps } from ".";

export const Chat = ({ ...props }: IconProps) => {
  return (
    <Icon {...props}>
      <>
        <path d="M25.344 10.3713V9H10.0877V10.3721L25.344 10.3713ZM8.71632 10.3713H10.0884V11.7434H8.71632V10.3713ZM25.344 10.3713H26.7162V11.7434H25.344V10.3713ZM12.8733 15.3343H14.2454L14.2446 16.7065H12.8724L12.8733 15.3343ZM17.0302 15.3343H18.4023L18.4015 16.7065H17.0294L17.0302 15.3343ZM21.1871 15.3343H22.5592L22.5584 16.7065H21.1863L21.1871 15.3343ZM28.1288 11.7849V21.4709H26.7567V22.8431H25.3845V24.2152L19.8149 24.2144V25.6279H18.4427V27H17.0706L17.0714 25.6279H15.6579V24.2558H10.0874V22.8836H8.71611V21.4709H7.34399V11.7849H8.71611V20.0987H10.0882L10.0874 21.4708H15.6579V22.843H17.03V24.2151H18.4022V22.843L19.8149 22.8422V21.4708H25.344V20.0987H26.7161V11.7849L28.1288 11.7849Z" />
      </>
    </Icon>
  );
};
