import { Icon, IconProps } from ".";

export const Heart = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.66531 5.3338H10.6709V6.66647H13.3292V5.3338H17.3348V6.66647H18.6675V7.99914H20.0003V12.0038H18.6675V13.3365H17.3348V14.6691H16.002V16.0018H14.6692V17.3345H13.3364V18.6671H10.6637V17.3345H9.33085V16.0018H7.99809V14.6691H6.66532V13.3365H5.33252V12.0038H3.99976V7.99914H5.33252V6.66647H6.66531V5.3338ZM13.3292 17.3278H12.0037V8.0058H13.3364V6.67313H17.3275V8.0058H18.6603V11.9971H17.3275V13.3298H15.9948V14.6625H14.662V15.9951H13.3292V17.3278Z"
        />
      </>
    </Icon>
  );
};
