import { Icon, IconProps } from ".";

export const GangIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 2.5V3.5H8V4.5H7V5.5H6V7.5H5V9.5H4V13.5H5V16.5H6V17.5H7V19.5H6V21.5H8V22.5H15V21.5H17V19.5H16V17.5H17V16.5H18V13.5H19V9.5H18V7.5H17V5.5H16V4.5H15V3.5H14V2.5H9ZM14 20.5V19.5H16V20.5H14ZM14 20.5V21.5H12V20.5H14ZM9 20.5H7V19.5H9V20.5ZM9 20.5H11V21.5H9V20.5ZM17 10.5V11.5H16V12.5H13V11.5H12V10.5H13V9.5H16V10.5H17ZM6 11.5V10.5H7V9.5H10V10.5H11V11.5H10V12.5H7V11.5H6ZM14 17.5H13V18.5H10V17.5H9V16.5H10V15.5H13V16.5H14V17.5Z"
        fill="currentColor"
      />
    </Icon>
  );
};
