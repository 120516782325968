import { Icon, IconProps } from "..";

export const Dufflebag = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <>
        <path
          d="M12.1156 4V4.8884H13.0039V5.78568H12.1067V4.89728H7.67388V5.78568H6.78555V6.67407H5.89721V10.2188H6.78555V11.116H5.88833V10.2277H5V5.77679H5.88833V4.8884H6.77667V4H12.1156Z"
          fill="currentColor"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.8833 5.77679H16.5572V6.66519H17.4456V7.55358H18.3339V8.44198H19.2222V12.0044H18.3339V12.8928H17.4456V13.7812H16.5572V14.6696H15.6689V15.558H14.7806V16.4464H13.8922V17.3348H13.0039V18.2232H12.1156V19.1116H11.2272V20H6.77667V19.1116H5.88833V18.2232H5V13.7723H5.88833V12.884H6.77667V11.9956H7.665V11.1072H8.55334V10.2188H10.33V9.33037H11.2183V8.44198H12.1067V7.55358H12.995V6.66519H13.8833V5.77679ZM11.2272 15.5491H12.1067V13.7812H11.2272V15.5491ZM10.3389 13.7723H11.2183V12.0044H10.3389V13.7723ZM12.995 17.3259H12.1156V15.558H12.995V17.3259ZM10.33 11.9956H8.56221V11.116H10.33V11.9956ZM14.7717 11.9956V11.116H13.8922V11.9956H14.7717ZM14.7806 11.1072H15.66V10.2277H14.7806V11.1072Z"
          fill="currentColor"
        />
      </>
    </Icon>
  );
};
