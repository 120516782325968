import { Icon, IconProps } from ".";

export const Twitter = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 5V6.875H5.33333V7.8125H6.16667V8.75H7V9.6875H7.83333V10.625H8.66667V11.5625H9.5V12.5H10.3333V14.375H9.5V15.3125H8.66667V16.25H7.83333V17.1875H7V18.125H6.16667V19.0625H5.33333V20H7V19.0625H7.83333V18.125H8.66667V17.1875H9.5V16.25H10.3333V15.3125H11.1667V14.375H12V15.3125H12.8333V16.25H13.6667V17.1875H14.5V18.125H15.3333V19.0625H16.1667V20H19.5V18.125H18.6667V17.1875H17.8333V16.25H17V15.3125H16.1667V14.375H15.3333V13.4375H14.5V12.5H13.6667V10.625H14.5V9.6875H15.3333V8.75H16.1667V7.8125H17V6.875H17.8333V5.9375H18.6667V5H17V5.9375H16.1667V6.875H15.3333V7.8125H14.5V8.75H13.6667V9.6875H12.8333V10.625H12V9.6875H11.1667V8.75H10.3333V7.8125H9.5V6.875H8.66667V5.9375H7.83333V5H4.5ZM7.83333 5.9375V6.875H8.66667V7.8125H9.5V8.75H10.3333V9.6875H11.1667V10.625H12V11.5625H12.8333V12.5H13.6667V13.4375H14.5V14.375H15.3333V15.3125H16.1667V16.25H17V17.1875H17.8333V18.125H18.6667V19.0625H16.1667V18.125H15.3333V17.1875H14.5V16.25H13.6667V15.3125H12.8333V14.375H12V13.4375H11.1667V12.5H10.3333V11.5625H9.5V10.625H8.66667V9.6875H7.83333V8.75H7V7.8125H6.16667V6.875H5.33333V5.9375H7.83333Z"
        fill="currentColor"
      />
    </Icon>
  );
};
