import { Icon, IconProps } from ".";

export const DollarBag = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.4935 4H16.5023V4.99938H17.5016V5.99875H18.501V6.99813H19.5004V9.00687H16.5023V9.99625H17.5016V10.9956H18.501V11.995H19.5004V16.0025H18.501V18.0012H17.5016V19.0006H15.5029V20H8.49726V19.0006H6.49851V18.0012H5.49913V16.0025H4.49976V11.995H5.49913V10.9956H6.49851V9.99625H7.49788V8.99688H9.49663V7.9975H12.4948V6.99813H13.4941V4.99938H14.4935V4ZM13.5041 15.0031H14.4935V15.9925H13.5041V15.0031ZM13.4941 12.9944V12.005H12.4948V11.0056H11.5054V12.005H10.506V12.9944H11.5054V14.0037H10.506V14.9931H11.5054V16.0025H10.506V16.9919H11.5054V17.9913H12.4948V16.9919H13.4941V16.0025H12.4948V14.9931H13.4941V14.0037H12.4948V12.9944H13.4941ZM14.5035 10.9956H13.5041V9.99625H12.5048V8.00749H13.4941V9.00687H14.4935V10.0062H16.4923V10.9956H15.4929V11.995H14.5035V10.9956ZM10.496 13.9938V13.0044H9.50664V13.9938H10.496ZM15.5029 12.005V12.9944H16.4923V12.005H15.5029Z"
        fill="currentColor"
      />
    </Icon>
  );
};
