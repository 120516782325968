import { Icon, IconProps } from ".";

export interface SparklesProps {
  inverted?: boolean;
}

export const Sparkles = ({ inverted, ...props }: SparklesProps & IconProps) => {
  return (
    <Icon {...props}>
      {inverted ? (
        <path d="M12.2014 9.38342L10.7614 6.50342L9.32141 9.38342L6.44141 10.8234L9.32141 12.2634L10.7614 15.1434L12.2014 12.2634L15.0813 10.8234L12.2014 9.38342ZM23.7214 15.1434L20.8413 9.38342L17.9613 15.1434L12.2014 18.0234L17.9613 20.9034L20.8413 26.6634L23.7214 20.9034L29.4814 18.0234L23.7214 15.1434ZM10.7614 20.9034L9.32141 23.7834L6.44141 25.2234L9.32141 26.6634L10.7614 29.5434L12.2014 26.6634L15.0813 25.2234L12.2014 23.7834L10.7614 20.9034Z" />
      ) : (
        <path d="M23.7214 9.38342L25.1614 6.50342L26.6014 9.38342L29.4814 10.8234L26.6014 12.2634L25.1614 15.1434L23.7214 12.2634L20.8413 10.8234L23.7214 9.38342ZM12.2014 15.1434L15.0813 9.38342L17.9613 15.1434L23.7214 18.0234L17.9613 20.9034L15.0813 26.6634L12.2014 20.9034L6.44141 18.0234L12.2014 15.1434ZM25.1614 20.9034L26.6014 23.7834L29.4814 25.2234L26.6014 26.6634L25.1614 29.5434L23.7214 26.6634L20.8413 25.2234L23.7214 23.7834L25.1614 20.9034Z" />
      )}
    </Icon>
  );
};
