import { Icon, IconProps } from "..";

export const Bicycle = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.9951 7.005V6.005H11.9951V4.995H15.0051V5.995H15.9951V4.995H19.0051V6.005H17.0051V7.005H16.0051V8.995H18.0051V9.995H19.0051V10.995H20.0051V13.005H19.0051V15.005H18.0051V16.005H14.9951V15.005H13.9951V14.005H12.0051V15.005H11.0051V16.005H10.0051V18.005H9.00512V19.005H5.99512V18.005H4.99512V17.005H3.99512V13.995H4.99512V12.995H5.99512V11.995H8.99512V11.005H6.99512V10.005H5.99512V8.995H7.99512V7.995H8.99512V6.995H10.0051V8.995H11.9951V7.995H14.9951V7.005H13.9951ZM7.00512 14.005V14.995H7.99512V14.005H7.00512ZM11.0051 10.995H10.0051V10.005H13.0051V9.005H14.9951V9.995H13.9951V11.995H12.9951V12.995H11.0051V10.995ZM6.00512 15.005H6.99512V15.995H6.00512V15.005ZM7.00512 16.995V16.005H7.99512V16.995H7.00512ZM8.99512 15.995H8.00512V15.005H8.99512V15.995ZM15.0051 12.995H15.9951V12.005H15.0051V12.995ZM16.0051 13.995V13.005H16.9951V13.995H16.0051ZM17.9951 12.995H17.0051V12.005H17.9951V12.995ZM16.9951 11.005V11.995H16.0051V11.005H16.9951ZM9.00512 12.005V12.995H9.99512V12.005H9.00512Z"
          fill="currentColor"
        />
      </>
    </Icon>
  );
};
